import React, { useState } from "react";
import {
  FormHelperText,
  Box,
  Button,
  Grid,
  DialogActions,
  DialogContent,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import useMounted from "../../../../hooks/useMounted";
import moment from "moment";

const TemplateForm = ({
  projectStartDate,
  dropdownProjects,
  setProjectStartDate,
  setSelectedProjectId,
  setShowUseProjectTemplate,
  setActiveStep,
  setSelectedTemplateId,
}) => {
  const mounted = useMounted();
  const [templateStartDate, setTemplateStartDate] = useState(moment());
  const [templateEndDate, setTemplateEndDate] = useState(
    moment().add(7, "days")
  );

  return (
    <Formik
      initialValues={{
        selectedTemplate: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        selectedTemplate: Yup.number().required(
          "Please select a template to continue "
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const selectedTempObj = dropdownProjects.filter(
            (project) => project.id === values.selectedTemplate
          );
          if (selectedTempObj.length) {
            const projectDetails = JSON.parse(
              selectedTempObj[0].project_details
            );
            setSelectedProjectId(projectDetails.id);
          }
          setSelectedTemplateId(values.selectedTemplate);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          toast.error("Something went wrong");
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ width: "100%" }}>
                  <FormControl className={classes.formControl} required>
                    <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                      Select Template
                    </InputLabel>
                    <Select
                      labelId="select-status"
                      label="Select Template"
                      name="selectedTemplate"
                      value={values.selectedTemplate}
                      error={Boolean(
                        touched.selectedTemplate && errors.selectedTemplate
                      )}
                      fullWidth
                      helperText={
                        touched.selectedTemplate && errors.selectedTemplate
                      }
                      placeholder="selectedTemplate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value="">none</MenuItem>
                      {dropdownProjects.length &&
                        dropdownProjects.map((individualProject) => (
                          <MenuItem
                            key={individualProject.id}
                            value={individualProject.id}
                          >
                            {individualProject.title}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                      {touched.selectedTemplate && errors.selectedTemplate}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <FormControl fullWidth required error={!templateStartDate}>
                    <DatePicker
                      label="Start Date"
                      value={templateStartDate}
                      onChange={(newValue) => {
                        if (newValue && moment.isMoment(newValue)) {
                          setTemplateStartDate(newValue);
                          setFieldValue(
                            "start_date",
                            newValue.format("YYYY-MM-DD")
                          );
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: "outlined",
                          className: "pt-datepicker-ip",
                        },
                      }}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <FormControl fullWidth required error={!templateEndDate}>
                    <DatePicker
                      label="End Date"
                      value={templateEndDate}
                      onChange={(newValue) => {
                        if (newValue && moment.isMoment(newValue)) {
                          setTemplateEndDate(newValue);
                          setFieldValue(
                            "end_date",
                            newValue.format("YYYY-MM-DD")
                          );
                        }
                      }}
                      minDate={templateStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: "outlined",
                          className: "pt-datepicker-ip",
                        },
                      }}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
            </Grid>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
              <Box>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Preview
                </Button>
              </Box>
              <Box sx={{ ml: 1 }}>
                <Button
                  disabled={isSubmitting}
                  size="large"
                  variant="contained"
                  onClick={() => setShowUseProjectTemplate(false)}
                  sx={{ backgroundColor: "#C0CBDE" }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

export default TemplateForm;
