import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  Switch,
  TextField,
  Typography,
  Grid,
  FormControl,
} from "@mui/material";
import { MobileDatePicker } from "@mui/lab";
import { addMinutes } from "date-fns";
import { Formik } from "formik";
import moment from "moment";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { createEvent, updateEvent } from "../../../slices/calendar";
import { useDispatch } from "../../../store";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const getInitialValues = (event, range) => {
  if (event) {
    return {
      allDay: event.allDay || false,
      color: event.color || "",
      description: event.description || "",
      end: moment(event.end_date),
      start: moment(event.start_date),
      title: event.title || "",
      submit: null,
    };
  }

  if (range) {
    return {
      allDay: false,
      color: "",
      description: "",
      end: new Date(range.end),
      start: new Date(range.start),
      title: "",
      submit: null,
    };
  }

  return {
    allDay: false,
    color: "",
    description: "",
    end: addMinutes(new Date(), 30),
    start: new Date(),
    title: "",
    submit: null,
  };
};

const CalendarEventForm = (props) => {
  const { event, onAddComplete, onCancel, onEditComplete, range } = props;
  const dispatch = useDispatch();
  const [eventStartDate, setEventStartDate] = moment();
  const [eventEndDate, setEventEndDate] = moment();

  return (
    <Formik
      initialValues={getInitialValues(event, range)}
      validationSchema={Yup.object().shape({
        allDay: Yup.bool(),
        description: Yup.string().max(5000),
        end: Yup.date().when(
          "start",
          (start, schema) =>
            start && schema.min(start, "End date must be later than start date")
        ),
        start: Yup.date(),
        title: Yup.string().max(255).required("Title is required"),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const data = {
            allDay: values.allDay,
            description: values.description,
            end: values.end_date.getTime(),
            start: values.start_date.getTime(),
            title: values.title,
          };

          if (event) {
            await dispatch(updateEvent(event.id, data));
          } else {
            await dispatch(createEvent(data));
          }

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          toast.success("Calendar updated!");

          if (!event && onAddComplete) {
            onAddComplete();
          }

          if (event && onEditComplete) {
            onEditComplete();
          }
        } catch (err) {
          console.error(err);
          toast.error("Something went wrong!");
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {event ? "Task" : "Add Task"}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <TextField
              disabled
              error={Boolean(touched.title && errors.title)}
              fullWidth
              helperText={touched.title && errors.title}
              label="Title"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
              variant="outlined"
            />
            <Box sx={{ mt: 2 }}>
              <TextField
                disabled
                error={Boolean(touched.description && errors.description)}
                fullWidth
                helperText={touched.description && errors.description}
                label="Description"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2, display: "none" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.allDay}
                    color="primary"
                    name="allDay"
                    onChange={handleChange}
                  />
                }
                label="All day"
              />
            </Box>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  required
                  error={!eventStartDate}
                >
                  <DatePicker
                    label="Event Start Date"
                    value={eventStartDate}
                    onChange={(newValue) => {
                      if (newValue && moment.isMoment(newValue)) {
                        setEventStartDate(newValue);
                        setEventEndDate(newValue);
                      }
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: "outlined",
                        className: "pt-datepicker-ip",
                      },
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  required
                  error={!eventEndDate}
                >
                  <DatePicker
                    label="Event End Date"
                    value={eventEndDate}
                    onChange={(newValue) => {
                      if (newValue && moment.isMoment(newValue)) {
                        setEventEndDate(newValue);
                      }
                    }}
                    minDate={eventStartDate}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: "outlined",
                        className: "pt-datepicker-ip",
                      },
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
            {Boolean(touched.end && errors.end) && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>{errors.end}</FormHelperText>
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              p: 2,
            }}
          >
            {/* {event && (
              <IconButton onClick={() => handleDelete()}>
                <TrashIcon fontSize="small" />
              </IconButton>
            )} */}
            <Box sx={{ flexGrow: 1 }} />
            <Button color="primary" onClick={onCancel} variant="text">
              Close
            </Button>
            {/* <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Confirm
            </Button> */}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CalendarEventForm;
